const main = document.querySelector('#main')
const inquiryModals = document.querySelectorAll('.js-inquiryModal')
const inquiryForm = document.querySelectorAll('.js-inquiryForm form')[0]
const inquiryFormWrapper = document.querySelectorAll('.js-inquiryForm .wpcf7')[0]
const successNotice = document.querySelectorAll('.js-inquiryFormSuccess')[0]
const inquiryFormContent = document.querySelector('.js-inquiryFormContent')

/**
 * Close modal on action
 * @returns {void}
 */
function closeModals() {
  if (main.classList.contains('has-modal')) {
    main.classList.remove('has-modal')
  }

  inquiryModals.forEach((modal) => {
    modal.classList.remove('is-active')
    modal.setAttribute('aria-hidden', 'true')
  })
}

if (inquiryForm && successNotice) {
  document.addEventListener( 'wpcf7mailsent', ( event ) => {
    successNotice.classList.add('is-active')
    inquiryFormContent.classList.add('success')
    inquiryFormWrapper.classList.add('is-hidden')
    inquiryForm.setAttribute('aria-disabled', 'true')
  });
}

document.addEventListener('click', (event) => {
  if (event.target.classList.contains('js-closeModal')) {
    closeModals()
  }
})
